import React from 'react';
import { Provider as ProviderRedux } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../services/utilities/queryClient';
import { ToastContainer } from 'react-toastify';
import { MainRoute } from '../modules';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, ThemedGlobalStyle } from '../reducers/theme';

let persistor = persistStore(store);

export const App = () => {
  return (
    <ProviderRedux store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Router>
                <MainRoute />
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  pauseOnFocusLoss
                  pauseOnHover
                />
              </Router>
            </BrowserRouter>
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </ProviderRedux>
  );
};
