import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/';

const MainGameRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../MainGame')),
  name: 'MainGame',
};

export default MainGameRoute;
